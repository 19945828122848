<template>
  <div
    class="page_bg_grey w-100 blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <div class="sasa-category mb-20">
      <banner-section location="shaniv_packages-top" />
      <div class="product-view-block mt-15">
        <h2 class="title fs23 mb-10">
          {{ $t("Packages") }}
        </h2>
        <div class="subcategories-part">
          <div class="sastech_company_logo">
            <router-link
              :to="{
                name: 'Sastech By Company',
                params: { company: 'shaniv' },
              }"
            >
              <img src="/new-assets/img/shaniv-logo.png" alt="" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="product-view-block">
        <h3>{{ $t("Hot sales") }}</h3>
        <div class="product-group">
          <div v-for="(item, index) in package_list" :key="index" class="product-item">
            <ProductCart :cartData="item" @addToCart="addToCart($event)" />
          </div>
        </div>
      </div>
    </div>
    <button 
      class="primary-btn btn-limit-width load-more_btn" 
      @click="loadMore()" 
      v-if="package_list.length < maxPackageNumber"
    >
      {{ $t("Show more") }}
    </button>

    <!-- ----- cart modal ----------- -->
    <CartModal v-on:closeModal="closeCartModal()" :cartModalData="tempProduct" :isPackageModal="true"></CartModal>
    
    <!-- mobile bottom fixed menu -->
    <MobileFiexedBottom></MobileFiexedBottom>

    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ProductCart from '../../components/ProductCart';
import  CartModal from "../../components/CartModal";
import MobileFiexedBottom from '../../components/cart/MobileFiexedBottom.vue';

import CartMixin from '../../utility/cart_mixin';
import BannerSection from "../../components/BannerSection";

export default {
  name: 'Sastech',
  mixins: [CartMixin],
  components: {
    BannerSection,
    ProductCart,
    Loading,
    CartModal,
    MobileFiexedBottom
  },
  data: () => {
    return {
      nextLabel: "<img class src='/assets/img/chevron.svg' />",
      prevLabel: "<img class src='/assets/img/chevron.svg' />",
      tempProduct: {},
      page_num: 0
    };
  },
  methods: {
    ...mapActions('shaniv_management', {
      getPackageList: 'getPackageList'
    }),
    ...mapActions('cartSystem', {
      initCart: 'initCart',
    }),
    addToCart(eachData) {
      this.tempProduct = Object.assign({}, eachData);
      this.tempProduct.package_discount_price = this.tempProduct.DISCOUNT_VATPRICE;

      this.tempProduct = JSON.parse(JSON.stringify(this.tempProduct));
      this.$modal.show('cartModal');
    },
    closeCartModal() {
      this.$modal.hide('cartModal');
    },
    loadMore() {
      const page_num = this.page_num + 1;
      this.getPackages(page_num);
    },
    getPackages(page) {
      page = +page || +this.page_num;
      this.page_num = page;
      let params = {
        active: true,
        page: page,
        per_page: 40
      };
      this.getPackageList(params);
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.shaniv_management.loading,
      package_list: (state) => state.shaniv_management.package_list,
      maxPackageNumber: (state) => state.shaniv_management.maxPackageNumber,
      cart: state => state.cartSystem.cart
    }),
  },
  async created() {
    await this.getPackages(0);
    this.initCart();
  },
};
</script>

<style lang="scss" scoped>
.sasa-category {
  padding: 0 30px 110px;
  .subcategories-part {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 24px;
    img {
      object-fit: contain;
    }
  }
}

.product-group {
  display: flex;
  flex-wrap: wrap;
  .product-item {
    width: calc(25% - 15px);
    margin-right: 20px;
  }
  .product-item:nth-child(5n),
  .product-item:first-child {
    margin-right: 0px;
  }
}

.sastech_company_logo {
  a {
    background: white;
    height: 110px;
    padding: 16px;
    display: inline-block;
    line-height: 110px;
    vertical-align: top;
    margin-right: 8px;
    width: 240px;
    text-align: center;
    margin-bottom: 14px;
  }
  img {
    height: 78px;
  }
}

.product-view-block {
  padding: 0 0 16px 0;
}

.load-more_btn {
  margin: 20px auto;
}

.ltr-type {
  .product-group {
    .product-item {
      margin-right: 0;
      margin-left: 20px;
    }
    .product-item:nth-child(5n),
    .product-item:first-child {
      margin-left: 0px;
    }
  }
}

@media screen and (max-width: 1250px) {
.product-group {
    .product-item {
      width: calc(50% - 20px);
    }
  }
}
@media screen and (max-width: 720px) {
.product-group {
    .product-item {
      width: 100%;
      margin: 0 !important;
    }
  }
}
</style>